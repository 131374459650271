import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


const DatenschutzPage = () => (
  <Layout>
    <SEO title="Impressum"/>
    <section id="content_1">
      <h1 className="headline">DATENSCHUTZERKLÄRUNG</h1>
      <div id="pic_1_index"/>
      <div id="pic_1_index_shadow"/>
    </section>

    <section id="content_2_schule">


      <article id="con_2_rechts">

        <h3 className="xsmall_sub">Datenschutz auf einen Blick</h3>

        <br/>

        <h4 className="xsmall_sub">Allgemeine Hinweise</h4>
        <p className="text">
          Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen
          Daten passiert,
          wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich
          identifiziert werden k&ouml;nnen.
          Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
          aufgef&uuml;hrten Datenschutzerkl&auml;rung.
        </p>
        <br/>

        <h4 className="xsmall_sub">Datenerfassung auf unserer Website</h4>
        <p className="text">
          <span style={{fontWeight: "bold"}}>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</span>
          <br/>
          Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
          k&ouml;nnen Sie dem Impressum dieser Website entnehmen.
          <br/><br/>
          <span style={{fontWeight: "bold"}}>Wie erfassen wir Ihre Daten?</span>
          <br/>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich
          z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
          Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst.
          Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs).
          Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.
          <br/><br/>
          <span style={{fontWeight: "bold"}}>Wof&uuml;r nutzen wir Ihre Daten?</span>
          <br/>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten.
          Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.
          <br/><br/>
          <span style={{fontWeight: "bold"}}>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</span>
          <br/>
          Sie haben jederzeit das Recht unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck
          Ihrer
          gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung,
          Sperrung oder L&ouml;schung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
          Datenschutz k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
          Außerdem haben Sie das Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung
          Ihrer
          personenbezogenen Daten zu verlangen. <br/>Details hierzu entnehmen Sie der Datenschutzerkl&auml;rung
          unter
          „Recht auf Einschr&auml;nkung der Verarbeitung“.
        </p>
        <br/>

        <h4 className="xsmall_sub">Analyse-Tools und Tools von Drittanbietern</h4>
        <p className="text">
          <span style={{fontWeight: "bold"}}>Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden.</span>
          <br/>
          Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen.
          Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
          zur&uuml;ckverfolgt werden.
          Sie k&ouml;nnen dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern.
          Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerkl&auml;rung.
          Sie k&ouml;nnen dieser Analyse widersprechen.
          &Uuml;ber die Widerspruchsm&ouml;glichkeiten werden wir Sie in dieser Datenschutzerkl&auml;rung informieren.
        </p>
        <br/>

        <h3 className="xsmall_sub">Allgemeine Hinweise und Pflichtinformationen</h3>
        <br/>

        <h4 className="xsmall_sub">Datenschutz</h4>
        <p className="text">
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst.
          Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
          Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
          Personenbezogene Daten sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
          Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie
          nutzen.
          Sie erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
          Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation per E-Mail)
          Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist
          nicht m&ouml;glich.
        </p>
        <br/>

        <h4 className="xsmall_sub">Hinweis zur verantwortlichen Stelle</h4>
        <p className="text">
          <span style={{fontWeight: "bold"}}>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</span>
          <br/>Ev. Grundschule L&ouml;hne-Bahnhof
          <br/>Ulrike Loh&ouml;fener
          <br/>K&ouml;nigstr. 47
          <br/>32584 L&ouml;hne
          <br/>Telefon: 05732 / 3301

          <br/>E-Mail:
          <a href="mailto:gs.loehne-bahnhof@schulen.loehne.de" className="mehr">gs.loehne-bahnhof@schulen.loehne.de</a>
          <br/><br/>
          Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit
          anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
          (z.B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
        </p>
        <br/>

        <h4 className="xsmall_sub">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
        <p className="text">
          Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich.
          Sie k&ouml;nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
          Mitteilung per E-Mail an uns. Die Rechtm&auml;ßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
          bleibt vom Widerruf unber&uuml;hrt.
        </p>
        <br/>

        <h4 className="xsmall_sub">Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen
          Direktwerbung (Art. 21 DSGVO)</h4>
        <p className="text"><span style={{fontWeight: "bold"}}>
                    Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie
                    jederzeit das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, gegen die
                    Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch f&uuml;r ein auf diese
                    Bestimmungen gest&uuml;tztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht,
                    entnehmen Sie dieser Datenschutzerkl&auml;rung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen
                    personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige
                    Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen oder
                    die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen
                    (Widerspruch nach Art. 21 Abs. 1 DSGVO).<br/>
                    Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht,
                    jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
                    derartiger Werbung einzulegen; dies gilt auch f&uuml;r das Profiling, soweit es mit solcher Direktwerbung
                    in Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten anschließend nicht
                    mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).
                    </span>
        </p>
        <br/>

        <h4 className="xsmall_sub">Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde</h4>
        <p className="text">
          Im Falle von Verst&ouml;ßen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
          Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres
          Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet
          anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <br/>

        <h4 className="xsmall_sub">Recht auf Daten&uuml;bertragbarkeit</h4>
        <p className="text">
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags
          automatisiert verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format
          aush&auml;ndigen zu lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an einen anderen
          Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <br/>

        <h4 className="xsmall_sub">Auskunft, Sperrung, L&ouml;schung und Berichtigung</h4>
        <p className="text">
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
          Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder L&ouml;schung dieser Daten.
          Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der
          im Impressum angegebenen Adresse an uns wenden.
        </p>
        <br/>

        <h4 className="xsmall_sub">Recht auf Einschr&auml;nkung der Verarbeitung</h4>
        <p className="text">
          Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          Hierzu k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:
        </p>
        <ul>
          <li className="aufzaehlung_gruener_kreis">
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
            ben&ouml;tigen wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung
            haben Sie das
            Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li className="aufzaehlung_gruener_kreis">
            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;ßig geschah / geschieht, k&ouml;nnen
            Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.
          </li>
          <li className="aufzaehlung_gruener_kreis">
            Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung,
            Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
            L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li className="aufzaehlung_gruener_kreis">
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung
            zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
            Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
          </li>
        </ul>
        <p className="text">
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten –
          von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder
          Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder
          juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen
          Union
          oder eines Mitgliedstaats verarbeitet werden.
        </p>

        <br/>
        <h3 className="xsmall_sub">Datenschutzbeauftragter</h3>
        <br/>

        <h4 className="xsmall_sub">Gesetzlich vorgeschriebener Datenschutzbeauftragter</h4>
        <p className="text"><span style={{fontWeight: "bold"}}>Die Kontaktdaten des Datenschutzbeauftragten lauten:</span>
          <br/>Beh&ouml;rdlicher Datenschutzbeauftragter f&uuml;r die &ouml;ffentlichen Schulen des Kreises Herford
          <br/>Schulamt f&uuml;r den Kreis Herford
          <br/>Amtshausstr. 3
          <br/>32051 Herford

          <br/>E-Mail:
          <a href="mailto:dsb-schulen-hf@kreis-herford.de" className="mehr">dsb-schulen-hf@kreis-herford.de</a>
        </p>
        <br/>

        <br/>
        <h3 className="xsmall_sub">Datenerfassung auf unserer Website</h3>
        <br/>

        <h4 className="xsmall_sub">Cookies</h4>
        <p className="text">
          Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen
          Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver
          und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und
          die Ihr Browser speichert.<br/>
          Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende
          Ihres Besuchs automatisch gel&ouml;scht. Andere Cookies bleiben auf Ihrem Endger&auml;t gespeichert bis Sie
          diese l&ouml;schen. Diese Cookies erm&ouml;glichen es uns, Ihren Browser beim n&auml;chsten Besuch
          wiederzuerkennen.<br/>
          Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und
          Cookies
          nur im Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschließen
          sowie
          das automatische L&ouml;schen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von
          Cookies kann die Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.<br/>
          Cookies, die zur Durchf&uuml;hrung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung
          bestimmter,
          von Ihnen erw&uuml;nschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von
          Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der
          Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste.
          Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese
          in dieser Datenschutzerkl&auml;rung gesondert behandelt.
        </p>
        <br/>

      </article>
    </section>

  </Layout>
)

export default DatenschutzPage